<template>
  <div>
    <h1>Einstellungen</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col cols="12">
            <user-select
                :value.sync="managers"
                :users="teamMembers"
                label="Bevollmächtigte Personen für mein Konto"
                multiple
                @removeItem="removeManager"
            />
          </v-col>
          <v-col cols="12">
            <v-btn color="primary" @click="saveSettings">Speichern</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
    </v-card>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import ConfirmDialog from '@/components/ConfirmDialog'
import {mapMutations, mapState} from "vuex";
import APIService from "@/services/APIService";
import UserSelect from "@/components/UserSelect";
import store from "@/store";

export default {
  name: "Settings",
  components: { ConfirmDialog, UserSelect },
  data() {
    return {
      teamMembers: [],
      managers: {},
    }
  },
  methods: {
    ...mapMutations(['updateManagers']),
    saveSettings () {
      APIService.updateUser({id: this.user.id, managers: this.managers})
      store.commit('updateManagers', this.managers)
    },
    getUserItemText(item) {
      return `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''} (${item.email})`;
    },
    removeManager(item) {
      const index = this.managers.indexOf(item.id)
      if (index >= 0) this.managers.splice(index, 1)
    },
  },
  computed: {
    ...mapState(['user']),
  },
  async beforeRouteEnter(to, from, next) {
    next( async vm => {
      vm.managers = [...store.state.user.managers]
      vm.teamMembers = await APIService.getTeamMembers(store.state.user.id)
    })
  }
}
</script>

<style scoped>

</style>
