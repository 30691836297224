<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Mitarbeiter</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="member.firstName"
                      label="Vorname"
                      required
                      :rules="requiredRule('Vorname')"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="member.lastName"
                      label="Nachname"
                      required
                      :rules="requiredRule('Nachname')"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="member.email"
                      label="E-Mail"
                      required
                      :rules="emailRules()"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveUser"
          >
            {{ member.id ? 'Speichern' : 'Hinzufügen' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/Snackbar'
import {mapState} from "vuex";
export default {
  name: 'MemberEditDialog',
  components: { Snackbar, ConfirmDialog },
  props: ['userId'],
  data: () => ({
    isEmailTaken: false,
    isLoginTaken: false,
    oldEmail: undefined,
    passwordRule: [
      value => (value && value.length >= 8) || `Das Passwort muss mindestens 8 Zeichen lang sein.`,
      value => (value && !!value.match(/\d/) && !!value.match(/[a-zA-Z]/)) || 'Das Passwort muss mindestens einen Buchstaben und ein Zahl enthalten'
    ],
    showDialog: false,
    showPassword: false,
    snackbarSuccess: {
      text: 'Benutzer erfolgreich gespeichert',
      icon: 'mdi-check-circle',
      color: 'success'
    },
    member: {},
  }),
  methods: {
    show (user) {
      this.member = { ...user }

      this.oldEmail = user?.email
      this.showDialog = true
    },
    close () {
      this.showDialog = false
      this.member = {}
      this.$refs.userForm.resetValidation()
    },
    async saveUser () {
      if (!this.$refs.userForm.validate()) {
        return
      }
      APIService.addTeamMember(this.userId, this.member).then(() => {
        this.$emit("updated", this.member)
        this.close()
        this.$refs.snackbar.show(this.snackbarSuccess)
      })
    },
    isEmailChanged() {
      return this.member.email !== this.oldEmail
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
    emailRules() {
      return [
        value => !!value || 'Email erforderlich.',
        !this.isEmailTaken || 'Die Email-Adresse ist bereits vergeben.'
      ]
    },
  },
  computed: {
    ...mapState(['user'])
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    },
  }
}
</script>

<style scoped>

</style>
