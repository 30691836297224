<template>
  <v-row justify="center">
    <v-dialog
        v-model="showDialog"
        persistent
        max-width="600px"
    >
      <v-card>
        <v-card-title>
          <span class="headline">Benutzer</span>
        </v-card-title>
        <v-card-text>
          <v-form ref="userForm" lazy-validation>
            <v-container>
              <v-row>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="user.firstName"
                      label="Vorname"
                      required
                      :rules="requiredRule('Vorname')"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                    sm="6"
                    md="6"
                >
                  <v-text-field
                      v-model="user.lastName"
                      label="Nachname"
                      required
                      :rules="requiredRule('Nachname')"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      v-model="user.companyName"
                      label="Firmenname (Optional)"
                  ></v-text-field>
                </v-col>
                <v-col
                    cols="12"
                >
                  <v-text-field
                      v-model="user.customerNumber"
                      label="Kundenummer (Optional)"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="user.email"
                      label="E-Mail"
                      required
                      :rules="emailRules()"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="user.street"
                      label="Strasse"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="user.zip"
                      label="PLZ"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="user.city"
                      label="Ort"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="user.lat"
                      label="Latitude"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="user.lng"
                      label="Longitude"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="user.phone"
                      label="Telefon"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6">
                  <v-text-field
                      v-model="user.fax"
                      label="Fax"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="user.logoUrl"
                      label="Logo-URL"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      v-model="user.homepage"
                      label="Homepage"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      v-model="user.password"
                      label="Passwort"
                      hint="Falls kein Passwort vergeben wird, wird dieses automatisch generiert."
                      required
                      :type="showPassword ? 'text' : 'password'"
                      @click:append="showPassword = !showPassword"
                      @blur="showPassword=false"
                      :rules="passwordRules"
                  ></v-text-field>
                </v-col>
                <v-col v-if="hasRole('admin')" cols="12">
                  <v-select v-model="user.role" :items="roles" label="Rolle"></v-select>
                </v-col>
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>Benutzer-Gruppen</v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div :key="group.id" v-for="group in user.userGroups">
                          {{group.GruppenBez}}
                        </div>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>

                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
              color="blue darken-1"
              text
              @click="close"
          >
            Abbrechen
          </v-btn>
          <v-btn
              color="blue darken-1"
              text
              @click="saveUser"
          >
            {{ user.id ? 'Speichern' : 'Hinzufügen'}}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <confirm-dialog ref="confirmDialog"/>
    <snackbar ref="snackbar"></snackbar>
  </v-row>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import Snackbar from '@/components/Snackbar'
import store from "@/store";
import {mapGetters} from "vuex";
export default {
  name: 'UserEditDialog',
  components: { Snackbar, ConfirmDialog },
  data: () => ({
    isEmailTaken: false,
    isLoginTaken: false,
    oldEmail: undefined,
    passwordRules: [
      value => (!value || value  && value.length >= 8) || `Das Passwort muss mindestens 8 Zeichen lang sein.x${!!value}x`,
      value => (!value || value && !!value.match(/\d/) && !!value.match(/[a-zA-Z]/)) || 'Das Passwort muss mindestens einen Buchstaben und ein Zahl enthalten'
    ],
    showDialog: false,
    showPassword: false,
    snackbarSuccess: {
      text: 'Benutzer erfolgreich gespeichert',
      icon: 'mdi-check-circle',
      color: 'success'
    },
    roles: [
      { text: 'Administrator', value: 'admin'},
      { text: 'Vertrieb', value: 'sale' },
      { text: 'Autor', value: 'author' },
      { text: 'Lizenzmanager', value: 'licenceManager' },
      { text: 'Benutzer', value: 'user' },
    ],
    user: {},
  }),
  methods: {
    show (user) {
      this.user = { ...user, role: user ? user.role : 'user' }

      this.oldEmail = user?.email
      this.showDialog = true
    },
    close () {
      this.showDialog = false
      this.user = {}
      this.$refs.userForm.resetValidation()
    },
    async saveUser () {
      if (!this.$refs.userForm.validate()) {
        return
      }
      if((!this.user.id || this.isEmailChanged()) && await APIService.isEmailTaken(this.user.email)) {

        this.isEmailTaken = true
        return
      }
      if (this.user.id) {
        APIService.updateUser(this.user).then(() => {
          store.dispatch('fetchUsers')
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      } else {
        APIService.saveUser(this.user).then(() => {
          store.dispatch('fetchUsers')
          this.close()
          this.$refs.snackbar.show(this.snackbarSuccess)
        })
      }
    },
    isEmailChanged() {
      return this.user.email !== this.oldEmail
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    },
    emailRules() {
      return [
        value => !!value || 'Email erforderlich.',
        !this.isEmailTaken || 'Die Email-Adresse ist bereits vergeben.'
      ]
    },
  },
  computed: {
    ...mapGetters(['hasRole'])
  },
  watch: {
    "user.email": function() {
      this.isEmailTaken = false
    },
  }
}
</script>

<style scoped>

</style>
