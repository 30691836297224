<template>
  <v-autocomplete
      :items="users"
      outlined
      :value="value"
      :label="label"
      item
      item-text="email"
      item-value="id"
      :filter="userFilter"
      :rules="rules"
      :multiple="multiple"
      @change="change"
  >
    <template v-slot:item="data">
      <v-list-item-content>
        <v-list-item-title v-html="fullName(data.item)"></v-list-item-title>
        <v-list-item-subtitle v-html="data.item.companyName"></v-list-item-subtitle>
        <v-list-item-subtitle v-html="data.item.email"></v-list-item-subtitle>
      </v-list-item-content>
    </template>
    <template v-slot:selection="data">
      <v-chip v-if="multiple"
          v-bind="data.attrs"
          :input-value="data.selected"
          close
          @click="data.select"
          @click:close="$emit('removeItem', data.item)"
      >
        {{getUserItemText(data.item)}}
      </v-chip>
      <template v-else>
        {{getUserItemText(data.item)}}
      </template>
    </template>
  </v-autocomplete>
</template>

<script>
import {propertySearch} from "@/utils/searchUtils";

export default {
  name: "UserSelect",
  props: {
    users: {
      required: true,
    },
    value: {
      required: true,
    },
    label: {
      required: true,
      type: String
    },
    multiple: {
      required: false,
      type: Boolean,
      default: false,
    },
    rules: {
      required: false,
      type: Array ,
    }
  },
  methods: {
    change(value) {
      console.log("Val", value)
      this.$emit('update:value', value)
    },
    getUserItemText(item) {
      return `${item.firstName ? item.firstName : ''} ${item.lastName ? item.lastName : ''} (${item.email})`;
    },
    userFilter(user, query) {
      return propertySearch(user, query, ['firstName','lastName', 'email','companyName'])
    },
    fullName(user) {
      return `${user.firstName} ${user.lastName}`
    },
    requiredRule (fieldName) {
      return [value => !!value || `${fieldName} erforderlich.`]
    }
  },
}
</script>

<style scoped>

</style>
