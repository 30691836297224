<template>
  <div>
    <h1>Benutzer</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createUser"
            >
              <v-icon left>mdi-plus</v-icon>
              Benutzer
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="users.length < 5"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="editUser(item)"
          >
            mdi-pencil
          </v-icon>
          <v-icon
              small
              @click="deleteUser(item)"
          >
            mdi-delete
          </v-icon>
        </template>
        <template v-slot:item.role="{ item }" class="ml-0">
          <v-chip
              :color="item.role === 'admin' ?  'red' : item.role==='user' ? 'green' : 'blue'"
              class="ma-0"
              small
              style="color: white; width: 80px"
          >
            {{item.role}}
          </v-chip>
        </template>
      </v-data-table>
    </v-card>
    <user-edit-dialog ref="userEditDialog"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import store from '@/store/index'
import UserEditDialog from '@/components/UserEditDialog'
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import {mapState} from "vuex";
export default {
  name: "UserList",
  mixins: [vuetifyTableMixin],
  components: {
    UserEditDialog,
    ConfirmDialog
  },
  data() {
    return {
      search: '',
      headers: [
        { text: 'Vorname', value: 'firstName' },
        { text: 'Nachname', value: 'lastName' },
        { text: 'Firma', value: 'companyName' },
        { text: 'Kundennummer', value: 'customerNumber' },
        { text: 'E-Mail', value: 'email' },
        {text: "Rolle", value: "role"},
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        },
      ]
    }
  },
  methods: {
    createUser () {
      this.$refs.userEditDialog.show()
    },
    editUser (item) {
      this.$refs.userEditDialog.show(item)
    },
    deleteUser: function (user) {
      this.$refs.confirmDelete.show({
        title: 'Benutzer entfernen',
        text: `Wollen sie <b>${user.firstName} ${user.lastName}</b> entfernen?`,
        confirm: 'entfernen'
      }).then(() => {
        APIService.deleteUser(user).then(() => {
          store.dispatch('fetchUsers')
          console.log('Item deleted')
        })
      })
    },
  },
  computed: {
    ...mapState(['users'])
  },
  beforeRouteEnter(to, from, next) {
    store.dispatch('fetchUsers').then(() => next())
  }
}
</script>

<style scoped>

</style>
