<template>
  <div>
    <h1>Team</h1>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col>
            <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
            ></v-text-field>
          </v-col>
          <v-spacer/>
          <v-col class="text-right">
            <v-btn
                rounded
                color="primary"
                dark
                @click="createUser"
            >
              <v-icon left>mdi-plus</v-icon>
              Mitarbeiter
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
          :headers="headers"
          :items="filteredTeamMembers"
          :search="search"
          :items-per-page="itemsPerPage"
          @update:options="updateOptions"
          class="elevation-1"
          :hide-default-footer="filteredTeamMembers.length < 5"
      >
        <template v-slot:item.actions="{ item }">
          <v-icon
              small
              class="mr-2"
              @click="sendNotification(item)"
          >
            mdi-email
          </v-icon>
          <v-icon
              small
              @click="deleteUser(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </v-card>
    <member-edit-dialog ref="memberEditDialog" :userId="userId" @updated="reloadTeamMembers"/>
    <confirm-dialog ref="confirmDelete"/>
  </div>
</template>

<script>
import APIService from '@/services/APIService'
import ConfirmDialog from '@/components/ConfirmDialog'
import {mapState} from "vuex";
import {vuetifyTableMixin} from "@/mixins/VuetifyTableMixin";
import MemberEditDialog from "@/components/MemberEditDialog";
export default {
  name: "TeamList",
  mixins: [vuetifyTableMixin],
  components: {
    MemberEditDialog,
    ConfirmDialog
  },
  props: ['userId'],
  data() {
    return {
      search: '',
      teamMembers: [],
      headers: [
        { text: 'Vorname', value: 'firstName' },
        { text: 'Nachname', value: 'lastName' },
        { text: 'E-Mail', value: 'email' },
        { text: 'Registriert', value: 'isRegistered' },
        {
          text: 'Aktionen',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ]
    }
  },
  methods: {
    createUser() {
      this.$refs.memberEditDialog.show()
    },
    editUser (item) {
      this.$refs.memberEditDialog.show(item)
    },
    deleteUser: function (member) {
      this.$refs.confirmDelete.show({
        title: 'Mitglied entfernen',
        text: `Wollen sie <b>${member.firstName} ${member.lastName}</b> von ihrer Mitgliederliste entfernen?<br>
               Alle an das Mitglied vergeb enen Lizenzen werden entfernt.`,
        confirm: 'entfernen'
      }).then(() => {
        APIService.removeTeamMember(this.userId, member.id).then(async () => {
         this.teamMembers = await APIService.getTeamMembers(this.userId)
          console.log('Item deleted')
        })
      })
    },
    reloadTeamMembers() {
      console.log("Reload team members")
      APIService.getTeamMembers(this.userId).then((members) => this.teamMembers = members)
    },
    sendNotification(item) {
      console.log("Nachricht versendet", item)
    }
  },
  computed: {
    ...mapState(['users', 'user']),
    filteredTeamMembers() {
      return this.teamMembers.filter(member => member.id !== this.user.id)
    }
  },
  async beforeRouteEnter(to, from, next) {
    const teamMembers = await APIService.getTeamMembers(to.params.userId)
    next(vm => vm.teamMembers = teamMembers)
  },
  async beforeRouteUpdate(to,from,next) {
    this.teamMembers = await APIService.getTeamMembers(to.params.userId)
    next()
  }

}
</script>

<style scoped>

</style>
